.report-list-container {
  padding: 1rem;
  padding-top: 3rem;
  flex-grow: 1;
  max-width: 40rem;
}

.report-list-container > h1 {
  font-family: "Fino Sans Thin";
  font-size: 2.5rem;
  text-align: center;
  width: 100%;
}

.report-list-heading {
  font-size: 24px;
  margin-bottom: 10px;
}

.report-list-footer {
  margin-bottom: 3rem;
  width: 48px;
}

.report-list {
  list-style: none;
  padding: 0;
}

.report-item {
  background-color: var(--gray6);
  border: 1px solid #ddd;
  padding: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
}

.report-item > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.report-item > div > div > img {
  height: 0.8rem;
}

.spacer {
  flex: 1 0 auto;
}

.unopened-pill-notification-container {
  background-color: var(--blue2);
  border-radius: 5rem;
  color: white;
  margin-right: 1rem;
  padding: .15rem 1rem;
}

/* Tablet */
@media (min-width: 576px) {
  .report-item {
    padding: 1.4rem;
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }

  .report-list-heading {
    font-size: 2rem;
    margin-bottom: 10px;
  }

  .report-list-container > h1 {
    font-size: 4rem;
  }

  .report-item > div > div > img {
    height: 1rem;
  }
}

/* Desktop */
@media (min-width: 768px) {
  .report-list-container {
    padding: 3rem;
    padding-top: 4rem;
  }

  .report-list-footer {
    margin-bottom: 3rem;
    width: 64px;
  }
}
