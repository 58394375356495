:root {
  background-color: #ffffff;
}

/* Mobile ------- */

/* Menu */
.menu-button {
  position: fixed;
  z-index: 1;
  margin-left: 1rem;
}

.react-tabs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.react-tabs__tab-list {
  position: fixed;
  border-bottom: none;
  top: 4rem;
  width: 100vw;
  height: calc(100vh - 4rem);
  background-color: var(--gray6);
  margin: 0;
  z-index: 1;
}

.react-tabs__tab-list li {
  flex-grow: 1;
}

.react-tabs__tab {
  display: block;
  border: none;
  font-size: 16pt;
  padding: 0;
  padding: 1rem;
  bottom: 0;
}

.react-tabs__tab::after {
  display: none;
}

.react-tabs__tab:first-child {
  margin-top: 6rem;
}

.react-tabs {
  max-width: 70rem;
}

.react-tabs__tab {
  background-color: var(--gray6);
  border-bottom: #000;
}

.react-tabs__tab--selected {
  background-color: white;
  cursor: pointer;
  color: #333;
  border-radius: 0;
}

.tab-panel-content {
  margin-top: 3.5rem;
}

/* Tab Panels */
.tab-panel h2 {
  margin-bottom: 0.4rem;
  font-family: "Fino Sans Light";
  font-size: 2rem;
  font-weight: 600;
  text-transform: lowercase;
  text-align: center;
}

.tab-panel h3 {
  margin: 2rem 0 .4rem 0;
  font-size: 1.5rem;
  background-color: var(--gray2);
  color: white;
  text-transform: uppercase;
  text-align: center;
}

.tab-panel h4 {
  font-size: 1.2rem;
  font-weight: 600;
}

.tab-panel {
  max-width: 100vw;
  padding-bottom: 8rem;
}

.tab-panel a {
  text-decoration: none;
  color: black;
  width: 100%;
}

.tab-panel .centered-text-container {
  margin-bottom: 1rem;
}

.tab-panel-content {
  padding: 1rem;
}

.tab-panel .header-images-container {
  position: relative;
}

.tab-panel .header-images-container img {
  object-fit: cover;
}

.tab-panel .background-image {
  display: block;
  width: 100%;
}

.tab-panel .foreground-image {
  position: absolute;
  bottom: 0;
  left: 50%; /* Center the image horizontally */
  transform: translate(-50%, 40%); /* Adjust to center the image */
  width: 60%; /* Take up half of the width */
  height: 90%;
  border: solid 1rem white;
}
/* End Tab Panels */

/* Back Button */

.report-menu-control-container > button {
  position: fixed;
  width: 8rem;
  margin: 3rem 0 0 1rem;
  padding: 0;
  font-size: 1.2rem;
  line-height: 1.2rem;
  border-radius: 8px;
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 3;
  color: black;
}

.report-menu-control-container > button:hover {
  text-decoration: underline;
}

.report-menu-control-container > button > div {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
}

.report-menu-control-container > button > div > img {
  height: 0.9rem;
  margin-right: 0.5rem;
  margin-bottom: 0.1rem;
  transform: rotate(180deg);
}

/* End back button */

/* Menu transition */
.report-menu-control-container .react-tabs__tab-list,
.report-menu-control-container button {
  transition: transform 150ms ease-out;
  transform: translateX(calc(-100vw));
}

.report-menu-control-container.menu-show .react-tabs__tab-list,
.report-menu-control-container.menu-show button {
  display: block;
  transform: translateX(0px);
}
/* End menu transition */

/* svg stuff */
.report-menu-control-container .menu-icon {
  position: fixed;
  bottom: 2rem;
  right: 1rem;
  z-index: 2;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}

.menu-icon svg {
  stroke: var(--white);
  stroke-width: 4px;
  stroke-linecap: round;
  margin: 1rem;
  width: 100%;
  height: 100%;
}

.report-menu-control-container svg line {
  transform-origin: center center;
  transform-box: fill-box;
  transition: transform 200ms ease;
}

.report-menu-control-container svg line:first-child {
  transform: translateY(1rem);
}

.report-menu-control-container svg line:nth-child(3) {
  display: none;
}

.report-menu-control-container svg line:nth-child(4) {
  display: none;
}

.report-menu-control-container svg line:last-child {
  transform: translateY(-1rem);
}

.menu-icon {
  background-color: var(--blue2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-show .menu-icon {
  background-color: var(--blue2);
}

.menu-show svg line {
  stroke: var(--white);
  stroke-width: 5px;
}

.menu-show svg line:first-child {
  transform: rotate(0deg);
}

.menu-show svg line:nth-child(3) {
  display: block;
  transform: translateX(-15px) translateY(12px) rotate(45deg);
}

.menu-show svg line:nth-child(4) {
  display: block;
  transform: translateX(-15px) translateY(-12px) rotate(-45deg);
}

.menu-show svg line:last-child {
  transform: rotate(0deg);
}

/* end svg stuff */

.divider {
  width: 100%;
  height: 1px;
  background-color: var(--gray4);
  margin: 1rem 0;
}

/* Tablet */
@media (min-width: 576px) {
  .tab-panel-content {
    margin-top: 5rem;
  }

  .tab-panel h2 {
    font-size: 3rem;
  }

  .tab-panel h3 {
    font-size: 1.7rem;
    padding: 0.4rem 0;
  }
}

/* Desktop */
@media (min-width: 768px) {
  .report-menu-control-container .react-tabs__tab-list {
    display: flex;
    flex-direction: row;
    transform: translateX(0px);
    position: relative;
    margin-top: 1rem;
    height: min-content;
    width: calc(100% - 2rem);
  }

  .report-menu-control-container.menu-show .react-tabs__tab-list {
    /* This is necessary in addition to the previous rule
    because the more specific rule earlier in the file was 
    overriding it  */
    display: flex;
  }

  .report-menu-control-container .react-tabs__tab {
    flex: 1 1 0px;
    text-align: center;
    padding: 1rem 0;
    margin: 0;
    border: solid 1px var(--gray4);
    border-top: solid 0.75rem var(--gray5);
    font-size: 1rem;
    min-height: 2.5rem;
  }

  .report-menu-control-container button {
    position: relative;
    display: flex;
    z-index: 0;
    padding: 0;
    transform: translateX(0px);
  }

  .report-menu-control-container .react-tabs__tab--selected {
    border-top: solid 0.75rem var(--blue2);
  }

  .report-menu-control-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    max-width: 60rem;
    flex: 1 0 0px;
  }

  .report-menu-control-container .report-container {
    width: 100%;
  }

  .menu-icon {
    display: none;
  }

  .react-tabs__tab:first-child {
    margin-top: 0rem;
  }

  .react-tabs__tab-panel {
    padding: 0 1rem;
  }

  .tab-panel-content {
    padding: 0;
    margin-top: 8rem;
  }

  .tab-panel .foreground-image {
    border: solid 2rem white;
  }
}
