.change-password-form-container {
  display: flex;
  margin-top: 0;
  width: 100%;
  height: 100%;
  background-color: white;
}

.change-password-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1rem;
  border: none;
  border-radius: 0;
  background-color: white;
}

form {
  width: 100%;
}

.change-password-form > h1 {
  margin-bottom: 0.5rem;
  width: 100%;
  text-align: left;
}

.change-password-input-container {
  margin-bottom: 0;
  width: 100%;
  display: flex;
}

.form-group {
  margin-bottom: 0.5rem;
}

.form-label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.form-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

input:disabled {
  background-color: #e5e5e5;
}

.change-password-button {
  padding: 12px 20px;
  background-color: var(--blue2);
  width: 100%;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 16pt;
}

.home-button {
  margin-top: 1rem;
}

button:disabled {
  background-color: var(--gray5);
  cursor: not-allowed;
}

.form-message-container {
  margin-top: 1rem;
  width: 100%;
}

.form-message {
  margin-top: 1rem;
  color: var(--gray2);
  width: 100%;
  text-align: center;
}

p {
  display: inline;
}

.validity-message {
  margin-top: 1rem;
  font-size: 14px;
  margin-bottom: 1rem;
}

.invalid {
  border-color: var(--red1);
}

.invalid-message {
  color: var(--red1);
}

.invalid-icon {
  margin-right: 5px;
}

.invalid-text {
  font-weight: bold;
}

.valid {
  border-color: green;
}

.valid-message {
  color: green;
}

.valid-icon {
  margin-right: 5px;
}

.valid-text {
  font-weight: bold;
}

.password-visibility-icon {
  position: relative;
  /* transform: translate(225px, -28px); */
  padding-left: 0.5rem;
  width: 18px;
  cursor: pointer;
  color: #999;
}

@media (min-width: 768px) {
  .change-password-form-container {
    margin-top: 3rem;
    flex-grow: 0;
    justify-content: center;
    background-color: transparent;
  }

  .change-password-form {
    max-width: 500px;
    padding: 44px;
    border-radius: 5px;
    background-color: transparent;
  }

  .change-password-form > h1 {
    margin-bottom: 1rem;
    width: 100%;
    text-align: left;
  }
}
