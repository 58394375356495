/* Welcome */
.welcome ul {
  margin:0;
  padding: 0;
  list-style-type: none;
}

.welcome li {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 1.2rem;
  padding-top: 0.75rem;
  width: 100%;
}

.welcome li::before {
  content: "";
  display: block;
  width: 1rem;
  height: 1rem;
  margin: 0.3rem 0.5rem 0 0.5rem;
  background-image: url('../images/diamond_bullet.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  flex: 0 0 auto;
}
/* End Welcome */
/* Product Recommendations */
.product-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

.product-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid var(--gray5);
  border-radius: 0.5rem;
  padding: 1rem;
}

.product-item > a > div > img {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1/1;
}
/* End Product Recommendations */

/* Start Lifestyle Recommendations */
.lifestyle-recommendations h4 {
  margin-top: 0.4rem;
}
/* End Lifestyle Recommendations */
/* Under the Microscope */

.mobile-hair-health-graph {
  margin-top: 1rem;
  width: 100%;
  max-width: 20rem;
}

.chart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

/* End Under the Microscope */
/* Facts and Figures */
.facts-and-figures {
  max-width: 100vw;
}
.facts-and-figures dl {
  /* list-style-type: none; */
  padding: 0;
  margin: 0;
}

.facts-and-figures dl > dt {
  font-weight: 600;
  margin-top: 1rem;
}

.facts-and-figures dl > dd {
  margin: 0;
  margin-top: 0.4rem;
}

.facts-and-figures .hair-anatomy img {
  width: 100%;
}

.facts-and-figures img {
  width: 100%;
}

.why-it-matters {
  margin-top: 1rem;
}

.dial-images {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin:1.5rem 0 0.5rem 0;
}
/* End Facts and Figures */

/* Tablet */
@media (min-width: 576px) {
  .product-grid {
    grid-template-columns: 1fr 1fr;
  }

  .dial-images {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

/* Desktop */
@media (min-width: 768px) {
  .desktop-hair-health-graph {
    margin-top: 1rem;
    width: 100%;
  }

  .welcome li::before {
    content: "";
    display: block;
    width: 1.3rem;
    height: 1.3rem;
    margin: 0.3rem 0.5rem 0 0.5rem;
  }

  .welcome li {
    font-size: 1.5rem;
  }
}
