@import "./Colors.css";

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
  margin: 1rem auto;
  padding: 44px;
  border-radius: 5px;
  background-color: var(--gray5);
}

h2 {
  margin-top: 0;
}

.input-container {
  margin-bottom: 10px;
  width: 16rem;
}

input {
  box-sizing: border-box;
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
}

.login-button {
  width: 100%;
  width: 10rem;
  padding: 8px;
  border: none;
  border-radius: 5px;
  background-color: var(--blue2);
  color: var(--white);
  cursor: pointer;
}

.login-page {
  min-height: 70vh;
}

.loader {
  margin: 10px 0;
  text-align: center;
}

.error-message {
  color: var(--red1);
  margin-top: 10px;
}

.logo_login {
  max-height: 50px;
  margin-bottom: 1rem;
}
