@import "./Colors.css";

.loader {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 20%;
  position: relative;
  border: 10px solid var(--blue2);
  top: 50%;
  animation: loader 4000ms infinite;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}