@import "./Colors.css";
@import "./Fonts.css";
h1,
h2,
h3,
h4,
h5,
h6,
p,
body {
  font-family: "Lora Regular", "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  margin: 0;
}

.home {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* header */
header {
  display: flex;
  height: 4rem;
  max-width: 100vw;
  justify-content: space-between;
  background-color: var(--white);
}

.home-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  border-bottom: solid 1px var(--gray1);
}

header > * {
  flex: 0 1 68px;
}

header > div:nth-child(1) {
  display: none;
}

.profile-icon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  padding: 1rem;
  color: var(--gray1);
}

.profile-icon > img {
  height: 30px;
  width: 30px;
}

.profile-icon > svg {
  height: 30px;
  width: 30px;
}

.report {
  display: flex;
  justify-content: center;
}

.logo1 {
  max-height: 30px;
  margin-left: 1rem;
}

/* Logo specific Header */
#GimmePoweredByBelluLabsLogo {
  margin-left: 1.25rem;
}

.home-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 65px;
  background-color: white;
  flex-grow: 1;
}

/* Drop Down User Menu */
/* App.css */

.dropdown-menu {
  position: absolute;
  top: 4rem; /* Adjust this value as needed */
  right: 0;
  background-color: var(--white);
  padding: 10px;
  z-index: 1;
  border: solid 1px;
  border-top: none;
  border-right: none;
}

.dropdown-menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.dropdown-menu li {
  cursor: pointer;
  padding: 10px;
}

.dropdown-menu li:hover {
  background-color: #f5f5f5;
}

.centered-text-container {
  text-align: center;
}

/* Media query for ipad or bigger */
@media (min-width: 400px) {
  header > div:nth-child(1) {
    /* fix spacing on web */
    display: block;
    flex-basis: 100px;
  }

  .logo1 {
    margin-left: 0;
  }
}
