:root {
  --blue1: #283044;
  --blue2: #778996;
  --blue3: #a4b0be;

  --light-tan: #ede7df;

  --red1: #bf847a;
  --red2: #e1c2bd;

  --gray1: #3a3b3d;
  --gray2: #525457;
  --gray3: #75787c;
  --gray4: #a5a9ae;
  --gray5: #d2d2d2;
  --gray6: #f2f2f2;
  --white: #ffffff;

  background-color: var(--white);
}