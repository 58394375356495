@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-UFVZ0e.ttf) format('truetype');
}

/* Lora */
@font-face {
  font-family: 'Lora Regular';
  src: local('Lora'), url('../fonts/lora/Lora-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Lora Bold';
  src: local('Lora'), url('../fonts/lora/Lora-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Lora Italic';
  src: local('Lora'), url('../fonts/lora/Lora-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Lora Bold Italic';
  src: local('Lora'), url('../fonts/lora/Lora-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Lora SemiBold';
  src: local('Lora'), url('../fonts/lora/Lora-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Lora SemiBold Italic';
  src: local('Lora'), url('../fonts/lora/Lora-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Lora Medium';
  src: local('Lora'), url('../fonts/lora/Lora-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Lora Medium Italic';
  src: local('Lora'), url('../fonts/lora/Lora-MediumItalic.ttf') format('truetype');
}

 /* Fino */
@font-face {
  font-family: 'Fino Sans Bold Italic';
  src: local('Fino Sans'), url('../fonts/fino-sans/Fino-Sans-Bold-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Fino Sans Bold';
  src: local('Fino Sans'), url('../fonts/fino-sans/Fino-Sans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Fino Sans Italic';
  src: local('Fino Sans'), url('../fonts/fino-sans/Fino-Sans-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Fino Sans Light Italic';
  src: local('Fino Sans'), url('../fonts/fino-sans/Fino-Sans-Light-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Fino Sans Light';
  src: local('Fino Sans'), url('../fonts/fino-sans/Fino-Sans-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Fino Sans Medium Italic';
  src: local('Fino Sans'), url('../fonts/fino-sans/Fino-Sans-Medium-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Fino Sans Medium';
  src: local('Fino Sans'), url('../fonts/fino-sans/Fino-Sans-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Fino Sans Regular';
  src: local('Fino Sans'), url('../fonts/fino-sans/Fino-Sans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Fino Sans Thin Italic';
  src: local('Fino Sans'), url('../fonts/fino-sans/Fino-Sans-Thin-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Fino Sans Thin';
  src: local('Fino Sans'), url('../fonts/fino-sans/Fino-Sans-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Fino Sans Ultra Thin Italic';
  src: local('Fino Sans'), url('../fonts/fino-sans/Fino-Sans-UltraThin-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Fino Sans Ultra Thin';
  src: local('Fino Sans'), url('../fonts/fino-sans/Fino-Sans-UltraThin.ttf') format('truetype');
}
